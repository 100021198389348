import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import Link from 'gatsby-link';






const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;







const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PRPhairBeardContPage = () => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section bg="#f7f7fb" py={4}>
      <Container>

      




        
        <Row className="align-items-center">
         
          <Col lg="12" md="9">
            <Box className=" pt-lg-0">
            <br />
            <Secondtext>
            Customised Anti-Ageing Packages:  <br /><br />
      </Secondtext>

      <Text>
      Take control of your ageing journey with our customised anti-ageing packages, carefully curated to meet your unique skincare goals and budget. Our comprehensive consultations allow us to assess your skin condition, discuss your concerns, and recommend tailored treatment strategies to address specific areas of ageing concern. Our bespoke packages combine multiple modalities to deliver synergistic results, ensuring that you achieve a more youthful, radiant complexion that reflects your inner vitality.  <br /><br />
      </Text>

      <Secondtext>
      Advanced Techniques for Lasting Results:  <br /><br />
</Secondtext>

<Text>
      Experience the transformative power of advanced anti-ageing techniques that go beyond traditional skincare approaches to deliver long-lasting results. Our clinic employs state-of-the-art technologies and innovative treatment modalities that target the underlying causes of ageing, including collagen depletion, muscle laxity, and skin damage. Whether you're looking to turn back the clock or maintain a youthful appearance, our expert practitioners are committed to helping you achieve age-defying results that defy expectations.  <br /><br />
</Text>

<Secondtext>
      Frown Lines for a Youthful Expression:  <br /><br />
</Secondtext>

<Text>
      Say goodbye to frown lines and hello to a more relaxed and youthful appearance. Our targeted anti-ageing treatments are designed to soften and smooth the lines between your eyebrows, commonly known as frown lines or "11" lines. Using advanced injectable techniques, such as Botulinum Toxin, we can effectively relax the underlying muscles responsible for these wrinkles, restoring a smoother, more refreshed look to your face. Whether you're bothered by deep furrows or fine lines, our personalised approach ensures natural-looking results that enhance your overall facial harmony and expression.  <br /><br />
</Text>

<Secondtext>
      Enhance Your Smile with Gummy Smile Correction:  <br /><br />
</Secondtext>

<Text>
      Transform your smile and boost your confidence with <Link to="/gummy-smile">gummy smile</Link> correction. If you feel self-conscious about excessive gum exposure when you smile, our tailored treatments can help create a more balanced and aesthetically pleasing smile line. By strategically injecting muscle-relaxing agents, we can reduce the elevation of your upper lip, minimizing the appearance of a gummy smile and revealing more of your beautiful teeth. Whether you're preparing for a special occasion or simply seeking to enhance your everyday smile, our gentle and precise techniques can help you achieve the smile of your dreams.  <br /><br />
</Text>

<Secondtext>
      Rejuvenate Your Forehead with Line Smoothing Treatments:  <br /><br />
</Secondtext>

<Text>
      Smooth away forehead lines and regain a youthful, relaxed appearance with our line-smoothing treatments. Whether you're dealing with horizontal forehead lines, often caused by repetitive facial expressions and sun exposure, or vertical lines between the eyebrows, our advanced anti-ageing techniques can help diminish their appearance and prevent further progression. Using a combination of injectables and advanced skincare, we target the underlying causes of forehead lines, restoring smoothness and vitality to your skin. Say goodbye to forehead furrows and hello to a fresher, more rejuvenated complexion that reflects your inner radiance.  <br /><br />
</Text>

<Secondtext>
      Brighten Your Eyes by Minimising Crows Feet:  <br /><br />
</Secondtext>

<Text>
      Illuminate your eyes and diminish the appearance of crow's feet with our specialised anti-ageing treatments. These fine lines that radiate from the corners of your eyes can add years to your appearance, but with our targeted interventions, you can achieve smoother, more youthful-looking skin around the eyes. Through a combination of injectables, we can soften crow's feet and restore a more vibrant, refreshed look to your eye area. Whether you're seeking to address existing lines or prevent future wrinkles, our customised approach ensures natural-looking results that enhance your overall beauty.  <br /><br />
</Text>

<Secondtext>
      Lift and Define Your Brow for a Youthful Look:  <br /><br />
</Secondtext>

<Text>
      Elevate your brows and rejuvenate your appearance with our brow lift treatments. As we age, the brow area can become droopy or heavy, resulting in a tired or aged appearance. Our specialised techniques can lift and define the brows, creating a more youthful and refreshed look to the entire face. Whether you're bothered by sagging brows or seeking to enhance your natural arch, our customised approach ensures tailored results that complement your unique facial features. Say hello to a more lifted and expressive brow line that rejuvenates your overall appearance and restores a youthful vitality to your face.  <br /><br />
</Text>

<Secondtext>
      Turn Up the Corners of Your Mouth and Banish Downturned Lips:  <br /><br />
</Secondtext>

<Text>
      Restore a cheerful and youthful appearance to your smile by addressing downturned <Link to="/corner-of-mouth">corners of the mouth</Link>. Downturned lips can create a perpetually sad or aged expression, but with our targeted treatments, you can turn up the corners of your mouth and regain a more uplifted and vibrant look. By strategically injecting dermal fillers or muscle-relaxing agents, we can subtly lift the corners of your lips, creating a more pleasing and harmonious smile line. Whether you're seeking to correct age-related changes or simply enhance your natural smile, our personalised approach ensures natural-looking results that boost your confidence and radiance.  <br /><br />
</Text>

<Secondtext>
      Smooth Out a Dimpled Chin for a Flawless Profile:  <br /><br />
</Secondtext>

<Text>
      Achieve a smoother and more refined chin profile with our dimpled chin correction treatments. Dimples or wrinkles on the chin can detract from an otherwise symmetrical and youthful appearance, but with our advanced techniques, you can enjoy a flawless and rejuvenated chin contour. Using injectables, we target the underlying causes of chin dimpling, restoring smoothness and definition to the lower face. Whether you're bothered by chin creases or seeking to enhance your facial harmony, our customised approach ensures natural-looking results that enhance your overall aesthetic and confidence.  <br /><br />
</Text>

<Secondtext>
      Is Anti-Ageing Safe? <br /><br />
</Secondtext>

<Text>
      At Dermamina, we understand that safety is a top priority when it comes to any cosmetic procedure. We are committed to providing our clients with safe and effective anti-ageing treatments that deliver noticeable results without compromising their well-being.  <br /><br />

      <b>Safety Protocols and Qualified Practitioners:</b>  <br /><br />
      Our clinic adheres to stringent safety protocols to ensure that every anti-ageing treatment is performed with the highest standards of care. Our team of experienced practitioners is fully trained and qualified in administering injectables, lasers, and other cosmetic procedures. We take the time to conduct thorough consultations, discussing your medical history, concerns, and expectations to determine the most suitable treatment approach for you.  <br /><br />

      <b>FDA-Approved Products and Techniques:</b>  <br /><br />
      We exclusively use FDA-approved products and state-of-the-art techniques in all our anti-ageing procedures. Whether it's Botulinum Toxin, dermal fillers, or laser therapy, you can trust that our treatments have undergone rigorous testing and meet the highest safety standards. We prioritize your health and comfort, ensuring that you receive safe and reliable treatments that deliver exceptional results.  <br /><br />

      

      
</Text>
          
<Secondtext>
      Monitoring and Follow-Up:  <br /><br />
</Secondtext>

<Text>
      Your safety and satisfaction are paramount to us, which is why we provide ongoing monitoring and support throughout your anti-ageing journey. We schedule follow-up appointments to assess your progress, address any concerns, and make any necessary adjustments to your treatment plan. Our goal is to ensure that you feel informed, supported, and confident in the safety and efficacy of your anti-ageing treatments.  <br /><br />
</Text>

<Text>
At Dermamina, we believe that looking and feeling your best should never compromise your health and safety. With our commitment to excellence and patient care, you can trust that you're in good hands every step of the way. Schedule a consultation with us today to learn more about our safe and effective anti-ageing treatments tailored to your unique needs.  <br /><br />
</Text>


</Box>
          </Col>
        </Row>
      </Container>
    </Section>
    <SeparatorEnd />
  </>
);

export default PRPhairBeardContPage;
